import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, documentoFiscalStore, financeiroCategoriaStore } from '@/store';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Número',
                sortable: true,
                value: 'numero',
                align: 'left',
            },
            {
                text: 'Modelo',
                sortable: true,
                value: 'modelo',
                align: 'left',
            },
            {
                text: 'Série',
                sortable: true,
                value: 'serie',
                align: 'left',
            },
            {
                text: 'Data de emissão',
                sortable: true,
                value: 'data_emissao',
                align: 'left',
            },
            {
                text: 'Valor Total',
                sortable: true,
                value: 'valor_total',
                align: 'left',
            },
            {
                text: 'CNPJ',
                sortable: true,
                value: 'cnpj',
                align: 'left',
            },
            {
                text: 'Razão Social',
                sortable: true,
                value: 'razao_social',
                align: 'left',
            },
            {
                text: 'Inscrição Estadual',
                sortable: true,
                value: 'inscricao_estadual',
                align: 'left',
            },
            {
                text: 'UF',
                sortable: true,
                value: 'uf',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
    }
    get allowView() {
        if (userToolStore.userToolsIndexed['Documentos Fiscais']) {
            return userToolStore.userToolsIndexed['Documentos Fiscais'][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed['Documentos Fiscais']) {
            return userToolStore.userToolsIndexed['Documentos Fiscais'][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed['Documentos Fiscais']) {
            return userToolStore.userToolsIndexed['Documentos Fiscais'][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed['Documentos Fiscais']) {
            return userToolStore.userToolsIndexed['Documentos Fiscais'][0].allow_delete;
        }
    }
    get documentosFiscais() {
        return documentoFiscalStore.documentosFiscais;
    }
    get FinanceiroCategoria() {
        return financeiroCategoriaStore.FinanceiroCategorias;
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão do Documento Fiscal?',
            text: 'Após exclusão o Documento Fiscal não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await documentoFiscalStore.deleteDocumentoFiscal(id);
                // @ts-ignore
                await documentoFiscalStore.getDocumentosFiscais();
                this.loading = false;
            }
        });
    }
    async mounted() {
        this.loading = true;
        await documentoFiscalStore.getDocumentosFiscais();
        this.loading = false;
    }
    routeShow(id) {
        this.$router.push({ name: 'main-documentosfiscais-show', params: { id } });
    }
};
List = __decorate([
    Component
], List);
export default List;
